import React, { useState } from 'react';
import AppHeader from '../components/app-header';
import Links from '../components/links';

import FormInput from '../components/ui/form-input';

import ConnectButton from '../components/ui/connect-button';
import Spacer from '../components/ui/spacer';
import LoginPageBackground from '../images/login.svg';
import { magic } from '../libs/magic';

interface Props {
  setAccount: React.Dispatch<React.SetStateAction<string | null>>;
}

const Login = ({ setAccount }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const [email, setemail] = useState('');

  const connect = async () => {
    try {
      setDisabled(true);
      //const accounts = await magic.wallet.connectWithUI();
      //const email =  (document.getElementById ("emailid")as HTMLInputElement).value;
      //const email = "rajesh.srimathi@gmail.com";
      const redirectURI = `${window.location.origin}`;
     await magic.auth.loginWithMagicLink({ email });
    // await magic.auth.loginWithMagicLink({ email,showUI:true, redirectURI });

     const userMetadata = await magic.user.getMetadata();
     const accounts = userMetadata.publicAddress;
      setDisabled(false);
      console.log('Logged in user:', accounts);
      if(accounts != null)
      
      localStorage.setItem('user', accounts);
      setAccount(accounts);
    } catch (error) {
      setDisabled(false);
      console.error(error);
    }
  };

  return (
    <div
      className="login-page"
      style={{
        backgroundImage: `url(${LoginPageBackground})`,
      }}
    >
      <AppHeader />
      <Spacer size={32} />
      
      <Spacer size={20} />
      <FormInput
        value={email}
        onChange={(e: any) => setemail(e.target.value)}
        placeholder="E-mail"
      />
      <ConnectButton onClick={connect} disabled={disabled} />
      <Links footer />
    </div>
  );
};

export default Login;
