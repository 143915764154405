import React from 'react';

import MyNfts from './my-nfts';
import NftTransfer from './nft-transfer';
import Divider from '../ui/divider';
import Card from '../ui/card';
import CardHeader from '../ui/card-header';

export const NFTs = () => {
  return (
    <Card>
      <CardHeader id="nfts">NFTs</CardHeader>
     
      <Divider />
      <MyNfts />
      <Divider />
      <NftTransfer />
    </Card>
  );
};

export default NFTs;
