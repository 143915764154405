import React, { useEffect, useState } from 'react';
import Loading from '../../images/loading.svg';
import nftImageOne from '../../images/nft-one.svg';
import nftImageTwo from '../../images/nft-two.svg';
import nftImageThree from '../../images/nft-three.svg';
import Spacer from '../ui/spacer';
import CardLabel from '../ui/card-label';
import { getNftContract } from '../../utils/contracts';
import { Networks } from '../../utils/networks';

const NFTDisplay = ({ id, name }: { id: string; name: string }) => {
  const getNftImage = () => {
    switch (Number(id) % 3) {
      case 1:
        return nftImageOne;
      case 2:
        return nftImageTwo;
      default:
        return nftImageThree;
    }
  };

  return (
    <div className="nft code">
      <div className="flex-row" style={{ justifyContent: 'flex-start' }}>
        <img src={getNftImage()} alt="nft-logo" />
        <div style={{ marginLeft: '12px' }}>
          <div className="nft-name">{name}</div>
          <Spacer size={5} />
          <div>Token ID: {id}</div>
        </div>
      </div>
    </div>
  );
};

interface NftDataType {
  tokenId: string;
  tokenURI: string;
}

const MyNfts = () => {
  const [nftData, setNftData] = useState<NftDataType[] | undefined>();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const contract = getNftContract();
  const publicAddress = localStorage.getItem('user');

  const formatNftMetadata = (nftIds: string[], tokenURIs: string[]) => {
    return nftIds.map((nftId: string, i: number) => {
      return {
        tokenId: nftId,
        tokenURI: tokenURIs[i],
      };
    });
  };

  const getOwnedNfts = async () => {
    if (!isRefreshing) {
      const network = localStorage.getItem('network');
      let nftIds: string[] = new Array();

      try {
        if(network == Networks.polygonLive)
        {
       // let nftIds: string[] = new Array();
       // let tokenURIs = new Map<string, string>();

        await fetch("https://api.polygonscan.com/api?module=account&action=tokennfttx&contractaddress=0x6518Dd8A0173b79879b0c0e48e943E1bde66C33A&address=" +publicAddress + "&page=1&offset=100&sort=asc&apikey=EN6ZUXYMQM8JBRRSQPE5XS8TZQP419F2GM")

        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return 0;
          }
        })
        .then(async data => {
          console.log(data.status);
        if(data.status == '1' && data.result.length > 0)
         {
        
            data.result.map(async (value:any) =>
            {
              if(value.tokenID)
              {
                if(nftIds.indexOf(value.tokenID) == -1)
                {
                nftIds.push(value.tokenID);
              //  tokenURIs.set(value.tokenID, await contract.methods.tokenURI(value.tokenID).call());
                }
                else
                {
                  nftIds.splice(nftIds.indexOf(value.tokenID), 1);
                 // tokenURIs.delete(value.tokenID);
                }               
              }
            });
            
            //const tokenURIs = await Promise.all(tokenURIPromises);
            //const nftMetadata = formatNftMetadata(nftIds, Array.from(tokenURIs.values()));
           // setNftData(nftMetadata);

            

             const tokenURIPromises = nftIds.map(async (nftId:string) => {
           
            let templateURI =  await contract.methods.tokenURI(nftId).call();
            templateURI = templateURI.replace("ipfs:/","https://nimilive.infura-ipfs.io/ipfs");
            return  (await fetch(templateURI).then((response) => response.json())).name;
          });
          const tokenURIs = await Promise.all(tokenURIPromises);
          const nftMetadata = formatNftMetadata(nftIds, tokenURIs);
          setNftData(nftMetadata);


   
         
         }
        });
      }
      else
      {

        //const nftIds = await contract.methods.getNftsByAddress(publicAddress).call();
         nftIds = await contract.methods.getNftsByAddress(publicAddress).call();
        

         const tokenURIPromises = nftIds.map(async (nftId:string) => {
        return await contract.methods.tokenURI(nftId).call();
      });
      const tokenURIs = await Promise.all(tokenURIPromises);
      const nftMetadata = formatNftMetadata(nftIds, tokenURIs);
      setNftData(nftMetadata);

      
      }

      
        
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (!nftData) {
      getOwnedNfts();
    }
  }, []);

  return (
    <div>
      <CardLabel
        style={{ height: '20px' }}
        leftHeader="My NFTs"
        rightAction={
          isRefreshing ? (
            <div className="loading-container">
              <img className="loading" alt="loading" src={Loading} />
            </div>
          ) : (
            <div
              onClick={() => {
                setIsRefreshing(true);
                setTimeout(() => {
                  setIsRefreshing(false);
                }, 500);
                getOwnedNfts();
              }}
            >
              Refresh
            </div>
          )
        }
      />
      {nftData && nftData.length > 0 ? (
        <div className="nft-list">
          {nftData.map(nft => {
            return <NFTDisplay id={nft.tokenId} key={nft.tokenId} name={nft.tokenURI} />;
          })}
        </div>
      ) : (
        <div className="code" style={{ color: '#777679' }}>
          No NFTs in wallet
        </div>
      )}
    </div>
  );
};

export default MyNfts;
